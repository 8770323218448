import React, { useState } from "react";
import PageLayout from "../../Components/PageLayout";
import { MdLocationPin } from "react-icons/md";
import { CityTab, LocationTab } from "../../Components/LocationTab/LocationTab";
import { locationAdd } from "../../data/LocationAdd";
import "./Contact.scss";
import BannerImage from "../../Components/BannerImge/BannerImage";
import { api } from "../../helper/api";
import ButtonLoader from "./../../Components/UI/ButtonLoader";
import { TextField } from "./../../Components/TextFeild";
import { toast, ToastContainer } from "react-toastify";

const Contact = () => {
	const [formData, updateFormData] = useState({
		reasons: "Business Inquiry",
		hidden: "contact"
	});
	const [loading, setLoading] = useState(false);
	const [isValidated, setIsValidated] = useState(false);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};
	let toastObj = {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	};
	const SubmitButton = document.querySelector(".btn-primary");
	const handleSubmit = async (e) => {
		setLoading(true);
		setIsValidated(true);
		e.preventDefault();
	
		try {
			// Save Inquiry to Database
			const response = await api({
				method: "post",
				url: "inquiry_new",
				data: formData,
				headers: { "Content-Type": "multipart/form-data" },
			});
	
			const jsonData = await response.data;
	
			if (jsonData.status === 200) {
				setLoading(false);
				setIsValidated(false);
				toast.success("Your Inquiry Submitted Successfully", toastObj);
				// Reset the Form
                setTimeout(() => {
				window.location.href = '/thank-you';
				}, 500);
				// Send Email Separately
				await api({
					method: "post",
					url: "send_email_new",
					data: formData,
					headers: { "Content-Type": "multipart/form-data" },
				});
	
				toast.success("Email Sent Successfully !!", toastObj);
	
				// Reset the Form
				// setTimeout(() => {
				// window.location.href = '/thank-you';
				// }, 500);
			} else {
				setLoading(false);
				toast.error("Please try Again!", toastObj);
			}
		} catch (err) {
			console.log(err);
			setLoading(false);
			toast.error("An error occurred. Please try again later.", toastObj);
		}
	};

	// const handleSubmit = async (e) => {
	// 	setLoading(true);
	// 	setIsValidated(true);
	// 	e.preventDefault();

	// 	try {
	// 		const response = await api({
	// 			method: "post",
	// 			url: "inquiry",
	// 			data: formData,
	// 			headers: { "Content-Type": "multipart/form-data" },
	// 		});
	// 		const jsonData = await response;
	// 		console.log(jsonData);
	// 		if (jsonData.status === 200) {
	// 			setLoading(false);
	// 			setIsValidated(false);
	// 			toast.success("Your Inquiry Submitted Successfully", toastObj);
	// 			updateFormData({});
	// 			var reSetForm = setInterval(() => {
	// 				window.location.reload(false);
	// 				clearInterval(reSetForm);
	// 			}, 1500);
	// 		} else {
	// 			setLoading(false);
	// 			toast.error("Please try Again!", toastObj);
	// 		}
	// 	} catch (err) {
	// 		console.log(err);
	// 		SubmitButton.innerText = "Submit";
	// 		if (err.response.status === 400 && err.response.status === 505) {
	// 			setLoading(false);
	// 			toast.error("Please try again later !", toastObj);
	// 		}
	// 	}
	// };

	return (
		<>
			<ToastContainer />
			<PageLayout
				title="Contact Us – Radical Minds Technologies Pvt. Ltd"
				metaDescription={
					"Connect with our customer service team for any inquiries or assistance."
				}
				className="contact-page"
			>
				<BannerImage
					Image={"./assets/banner/contact.jpg"}
					PageName={"Contact Us"}
					BannerTitle={["We’re here to help you"]}
				/>
				<section className="section-space contact_form">
					<div className="container position-relative">
						<div className="form-wrapper">
							<div className="row">
								<div className="col-xs-12 col-sm-5">
									<h2 className="section-heading">
										Get in touch with us
									</h2>
									<h4 className="form-heading">
										Select reason
									</h4>
									<div className="form-group customcheckradio">
										<span className="check_list_item first">
											<label>
												<div className="iradio_minimal-yellow checked">
													<input
														type="radio"
														name="reasons"
														value="Business Inquiry"
														defaultChecked={
															formData.reasons ===
															"Business Inquiry"
														}
														onChange={handleChange}
													/>
													<ins className="iCheck-helper"></ins>
												</div>
												<span className="list-item-label">
													Business Inquiry
												</span>
											</label>
										</span>
										<span className="check_list_item first">
											<label>
												<div className="iradio_minimal-yellow checked">
													<input
														type="radio"
														name="reasons"
														value="Alliances & Partnerships"
														defaultChecked={
															formData.reasons ===
															"Alliances & Partnerships"
														}
														onChange={handleChange}
													/>
													<ins className="iCheck-helper"></ins>
												</div>
												<span className="list-item-label">
													Alliances & Partnerships
												</span>
											</label>
										</span>
										<span className="check_list_item first">
											<label>
												<div className="iradio_minimal-yellow ">
													<input
														type="radio"
														name="reasons"
														value="Analysts & Media Relationships"
														defaultChecked={
															formData.reasons ===
															"Alliances & Partnerships"
														}
														onChange={handleChange}
													/>
													<ins className="iCheck-helper"></ins>
												</div>
												<span className="list-item-label">
													Analysts & Media
													Relationships
												</span>
											</label>
										</span>
										<span className="check_list_item first">
											<label>
												<div className="iradio_minimal-yellow ">
													<input
														type="radio"
														name="reasons"
														value="Career Enquiry"
														defaultChecked={
															formData.reasons ===
															"Career Enquiry"
														}
														onChange={handleChange}
													/>
													<ins className="iCheck-helper"></ins>
												</div>
												<span className="list-item-label">
													Career Enquiry
												</span>
											</label>
										</span>
									</div>
									<div className="contact-image"></div>
								</div>
								<form
									className="col-xs-12 col-sm-7 bg-gray"
									onSubmit={handleSubmit}
								>
									<TextField
										required
										onChange={handleChange}
										id="fullname"
										label={"Full Name"}
										className={"w-100"}
										type="text"
										isValidated={isValidated}
										defaultValue={formData.fullname ?? ""}
									/>
									<TextField
										required
										onChange={handleChange}
										id="organization"
										label={"organization"}
										className={"w-100"}
										type="text"
										isValidated={isValidated}
										defaultValue={
											formData.organization ?? ""
										}
									/>
									<TextField
										required
										onChange={handleChange}
										id="jobtitle"
										label={"Job Title"}
										className={"w-100"}
										type="text"
										isValidated={isValidated}
										defaultValue={formData.jobtitle ?? ""}
									/>
									<TextField
										required
										onChange={handleChange}
										id="email"
										label={"Email Address"}
										className={"w-100"}
										type="text"
										isValidated={isValidated}
										defaultValue={formData.email ?? ""}
									/>
									<TextField
										required
										id="phone"
										label={"Mobile Number"}
										className={"w-100"}
										type="number"
										onChange={(e) => {
											if (
												/^[a-zA-Z]+$/.test(
													e.target.value
												)
											) {
												return false;
											}
											handleChange(e);
										}}
										isValidated={isValidated}
										defaultValue={formData.phone ?? ""}
									/>
									<TextField
										required
										onChange={handleChange}
										id="msg"
										label={"Your Message"}
										className={"w-100"}
										type="tel"
										isValidated={isValidated}
										defaultValue={formData.msg ?? ""}
										multiline={2}
									/>
									<div className="form-group">
										<ButtonLoader
											type="Submit"
											loading={loading}
											className="btn-primary btn-b"
										>
											Submit
										</ButtonLoader>
										<span className="ajax-loader active"></span>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
				<div className="container pt-0">
					<h2 className="section-heading">Our Presence</h2>
					<LocationTab active={0}>
						{locationAdd.map((tab, idx) => (
							<LocationTab.LocationPanel
								key={`Tab-${idx}`}
								tab={tab.contury}
							>
								<CityTab active={0} key={idx}>
									{tab.cities.map((data) => (
										<CityTab.CityPanel
											key={`Tab-${idx}`}
											tab={data.city}
										>
											{data.content.map(
												(data, data_id) => (
													<React.Fragment
														key={data_id}
													>
														<img
															src={data.img}
															alt={`Radical  Mind`}
															className="img-fluid city_moral"
														/>
														<div className="address-wrap">
															<div className="row ">
																<div className="col-md-8 col-7  address-box">
																	<address>
																		<p>
																			{
																				data.add
																			}
																		</p>
																	</address>

																	<div className="contact-number">
																		{data.mob.map(
																			(
																				mob
																			) =>
																				mob
																		)}
																		{/* <a
																			href={`tel:${data.mob}`}
																		>
																			{
																				data.mob
																			}
																		</a> */}
																	</div>
																	<div className="get-direction">
																		<a
																			href={
																				data.mapUrl
																			}
																			target="_blank"
																			rel="noreferrer"
																		>
																			<span className="icon">
																				<MdLocationPin />
																			</span>{" "}
																			Get
																			Direction
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</React.Fragment>
												)
											)}
										</CityTab.CityPanel>
									))}
								</CityTab>
							</LocationTab.LocationPanel>
						))}
					</LocationTab>
				</div>
			</PageLayout>
		</>
	);
};

export default Contact;
